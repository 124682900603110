import { inject, Injectable, signal } from '@angular/core';
import {
  FK_APP_STORAGE,
  FK_CONTENT_AGREEMENT_NOTI_MESSAGE_VIEWED_KEY,
  FK_KEY_LOGIN_TOKEN,
  FK_LOGIN_BY_REDIRECT,
  FK_LOGIN_BY_REDIRECT_START,
  FK_PUSH_TOKEN_KEY_V2,
  FK_SAVED_LANGUAGE_KEY
} from '@pixels/client/ngforage/forage-key';
import { RxNgForage } from '@pixels/client/ngforage/rx-ngforage';
import { EProviderId } from '@pixels/universal/model/firebase/firebase.model';
import { SavedDeviceTokenParam } from '@pixels/universal/model/push-token/push.model';
import { isEqual, isNil, omitBy } from 'es-toolkit';
import { defer, finalize, forkJoin, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { Cacheable, CacheBuster } from 'ts-cacheable';

const version = '1.0.1';
const cacheBuster$ = new Subject<void>();

@Injectable({ providedIn: 'root' })
export class AppStateService {
  public readonly state = signal<AppStorage>({ version });
  private readonly forage = inject(RxNgForage);

  public setItem<T extends keyof AppStorage>(key: T, value: AppStorage[T]): Observable<AppStorage[T]> {
    return this.getAll().pipe(
      switchMap(appStorage => this.setAll(Object.assign(appStorage, { [key]: value }))),
      map(() => value)
    );
  }

  public setItemNonNullable<T extends keyof AppStorage>(key: T, value: AppStorage[T]): Observable<NonNullable<AppStorage[T]>> {
    return this.setItem(key, value) as Observable<NonNullable<AppStorage[T]>>;
  }

  public getItem<T extends keyof AppStorage>(key: T, defaultValue?: AppStorage[T]): Observable<AppStorage[T] | undefined> {
    return this.getAll().pipe(map(appStorage => appStorage[key] ?? defaultValue));
  }

  public getItemNonNullable<T extends keyof AppStorage>(key: T, defaultValue?: AppStorage[T]): Observable<NonNullable<AppStorage[T]>> {
    return this.getItem(key, defaultValue) as Observable<NonNullable<AppStorage[T]>>;
  }

  public removeItem(key: keyof AppStorage): Observable<AppStorage> {
    return this.getAll().pipe(
      switchMap(appStorage => {
        delete appStorage[key];
        return this.setAll(appStorage);
      })
    );
  }

  public migrate(): Observable<any> {
    return defer(() => {
      console.time('migration');
      return this.getAll();
    }).pipe(
      switchMap(appStorage => {
        console.log(JSON.stringify(appStorage, null, 2));
        if (appStorage.version === version) {
          return of(undefined);
        }
        return forkJoin([
          of(appStorage.enabledRecommendedFriendFromAgreement),
          of(appStorage.initializedRecommendedFriendAlarm),
          this.forage.getItem<SavedDeviceTokenParam>(FK_PUSH_TOKEN_KEY_V2),
          this.forage.getItem<string>(FK_KEY_LOGIN_TOKEN),
          this.forage.getItem<string>(FK_SAVED_LANGUAGE_KEY),
          this.forage.getItem<boolean>(FK_CONTENT_AGREEMENT_NOTI_MESSAGE_VIEWED_KEY),
          this.forage.getItem<boolean>(FK_LOGIN_BY_REDIRECT),
          this.forage.getItem<EProviderId>(FK_LOGIN_BY_REDIRECT_START),
        ]).pipe(
          switchMap(([
                       enabledRecommendedFriendFromAgreement,
                       initializedRecommendedFriendAlarm,
                       savedDeviceTokenParam,
                       talkPlusToken,
                       language,
                       viewedContentAgreementNotiMessage,
                       isRedirectLogin,
                       providerIdForLoginByRedirect
                     ]) => forkJoin([
            this.setAll(Object.assign({}, omitBy(appStorage, isNil), {
              version,
              enabledRecommendedFriendFromAgreement,
              initializedRecommendedFriendAlarm,
              savedDeviceTokenParam,
              talkPlusToken,
              savedLanguage: language,
              viewedContentAgreementNotiMessage,
              isRedirectLogin,
              providerIdForLoginByRedirect
            })),
            this.forage.removeItem(FK_PUSH_TOKEN_KEY_V2),
            this.forage.removeItem(FK_KEY_LOGIN_TOKEN),
            this.forage.removeItem(FK_SAVED_LANGUAGE_KEY),
            this.forage.removeItem(FK_CONTENT_AGREEMENT_NOTI_MESSAGE_VIEWED_KEY),
            this.forage.removeItem(FK_LOGIN_BY_REDIRECT),
            this.forage.removeItem(FK_LOGIN_BY_REDIRECT_START),
          ]))
        );
      }),
      finalize(() => console.timeEnd('migration'))
    );
  }

  @CacheBuster({ cacheBusterNotifier: cacheBuster$ })
  private setAll(appStorage: AppStorage): Observable<AppStorage> {
    return this.forage.setItem<AppStorage>(FK_APP_STORAGE, appStorage).pipe(
      tap(appStorage => {
        if (!isEqual(this.state(), appStorage)) {
          this.state.set({ ...appStorage });
        }
      })
    );
  }

  @Cacheable({ cacheBusterObserver: cacheBuster$ })
  private getAll(): Observable<AppStorage> {
    return this.forage.getItem<AppStorage>(FK_APP_STORAGE, { version: '' }).pipe(
      tap(appStorage => {
        if (!isEqual(this.state(), appStorage)) {
          this.state.set({ ...appStorage });
        }
      })
    );
  }
}

export interface AppStorage {
  version: string;
  savedLanguage?: string | undefined;
  talkPlusToken?: string | undefined;
  savedDeviceTokenParam?: SavedDeviceTokenParam | undefined;
  viewedContentAgreementNotiMessage?: boolean | undefined;
  isRedirectLogin?: boolean | undefined;
  providerIdForLoginByRedirect?: EProviderId | undefined;
  enabledRecommendedFriendFromAgreement?: boolean | undefined;
  initializedRecommendedFriendAlarm?: boolean | undefined;
  loggerDisabled?: boolean | undefined;
  bannerHeight?: number | undefined;
  boardPostListMuted?: boolean | undefined;
}

export function migrateForage(service: AppStateService): () => Observable<any> {
  return () => service.migrate();
}
